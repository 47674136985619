import React, {FC} from 'react';
import './App.css';
import {Box, Typography} from "@mui/material";

import {ReactComponent as LogoBox} from "../shared/images/logo/senderfox-box.svg";

export const App: FC = () => {

  return (
      <Box sx={{width: '100vw', height: '100vh', overflow: 'hidden'}}>
          <Box sx={{width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '24px'}}>
              <LogoBox style={{width: 'min(90vw, 512px)', height: 'auto'}}/>
              <Typography variant={"h3"} align={"center"}>Service in development</Typography>
          </Box>
      </Box>
  );
}

